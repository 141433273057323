<template>
  <div style="width: 100%">
    <crud-md></crud-md>
  </div>
</template>

<script>
export default {
  components: {
    CrudMd: () => import("@/components/crud-md"),
  },
};
</script>
